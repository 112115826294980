import {Box, SxProps, Theme} from "@mui/material";
import {useInView} from "react-intersection-observer";

interface ImageProps {
  alt?: string;
  disableInView?: boolean;
  onClick?: () => void;
  src?: string;
  sx?: SxProps<Theme>;
}

export const Image = ({ alt = "", onClick, src, sx }: ImageProps) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
  });

  return !src ? null : (
    <>
      {inView ? (
        <Box alt={alt} component="img" src={src} sx={sx} onClick={onClick} />
      ) : (
        <Box ref={ref} sx={sx} />
      )}
    </>
  );
};
