import {Button, Stack} from "@mui/material";
import {LoaderFunctionArgs, useLoaderData} from "react-router-dom";
import {Background} from "../components/Background";
import {ContactForm} from "../components/ContactForm";
import {Container} from "../components/Container";
import {Cover} from "../components/Cover";
import {Download} from "../components/Download";
import {DynamicFigures} from "../components/DynamicFigures";
import {DynamicGrid} from "../components/DynamicGrid";
import {DynamicList} from "../components/DynamicList";
import {ImageList} from "../components/ImageList";
import {PageList} from "../components/PageList";
import {PdfList} from "../components/PdfList";
import {Quote} from "../components/Quote";
import {Slider} from "../components/Slider";
import {SuccessStories} from "../components/SuccessStories";
import {Tab} from "../components/Tab";
import {Testimonials} from "../components/Testimonials";
import {Text} from "../components/Text";
import {Video} from "../components/Video";
import {ZoneList} from "../components/ZoneList";
import i18n from "../i18n";
import {CustomPageApi} from "../utilities/api/CustomPageApi";
import {getResponsiveValue} from "../utilities/hooks";
import {Column} from "../utilities/interface";
import MapPage from "./MapPage";
import OpportunityListPage from "./OpportunityListPage";
import OpportunityPage from "./OpportunityPage";
import SuccessStoryListPage from "./SuccessStoryListPage";
import TestimonialListPage from "./TestimonialListPage";
import {Lotto} from "../components/Lotto";
import {VideoList} from "../components/VideoList";

export const customPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const { slug } = params;

  if (slug == null) {
    return null;
  }

  const data = await CustomPageApi.getPage(slug, i18n.language);

  return data;
};

const DynamicComponent = (col: Column) => {
  switch (col.type) {
    case "video": {
      return <Video {...col} />;
    }

    case "cover": {
      return <Cover {...col} />;
    }

    case "background": {
      return <Background {...col} />;
    }

    case "text": {
      return <Text {...col} />;
    }

    case "figures": {
      return <DynamicFigures {...col} />;
    }

    case "slider": {
      return <Slider {...col} />;
    }

    case "grid": {
      return <DynamicGrid {...col} />;
    }

    case "download_file": {
      return <Download {...col} />;
    }

    case "success_stories": {
      if (col.variant === "slider") {
        return <SuccessStories {...col} />;
      } else if (col.variant === "list") {
        return <SuccessStoryListPage />;
      }

      return null;
    }

    case "testimonials": {
      if (col.variant === "slider") {
        return <Testimonials {...col} />;
      } else if (col.variant === "list") {
        return <TestimonialListPage />;
      }

      return null;
    }

    case "contact_form": {
      return <ContactForm {...col} />;
    }

    case "button": {
      return (
        <Button
          href={col.link}
          sx={{ alignSelf: "center" }}
          variant="contained"
        >
          {col.button_text}
        </Button>
      );
    }

    case "map": {
      return <MapPage {...col} />;
    }

    case "opportunity_list": {
      return <OpportunityListPage {...col} />;
    }

    case "opportunity": {
      return <OpportunityPage {...col} />;
    }

    case "image_list": {
      return <ImageList {...col} />;
    }

    case "video_list": {
      return <VideoList {...col} />;
    }

    case "quote": {
      return <Quote {...col} />;
    }

    case "list": {
      return <DynamicList {...col} />;
    }

    case "zone_list": {
      return <ZoneList {...col} />;
    }

    case "pdf_list": {
      return <PdfList {...col} />;
    }

    case "tab": {
      return <Tab {...col} />;
    }

    case "page_list": {
      return <PageList {...col} />;
    }

    case "competition": {
      return <></>; // <Competition {...col} />;
    }

    case "lotto": {
      return <Lotto {...col} />;
    }

    default: {
      return (
        <Text content={JSON.stringify(col as any)} title={(col as any).type} />
      );
    }
  }
};

export const CustomPage = () => {
  const data = useLoaderData() as Awaited<ReturnType<typeof customPageLoader>>;
  console.log("page", data);

  let skipNext = false;

  return data == null ? null : (
    <Stack
      spacing={14}
      sx={{
        backgroundColor: getResponsiveValue(
          data.backgroundColorMobile,
          data.backgroundColor
        ),
        backgroundImage: getResponsiveValue(
          `url(${data.backgroundImageMobile})`,
          `url(${data.backgroundImage})`
        ),
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        flex: 1,
      }}
    >
      {data.cols.map((col, index) => {
        if (skipNext) {
          return null;
        }

        if (col.type === "slider" && col.variant === "lotto") {
          skipNext = true;

          return (
            <Container key={index}>
              <Stack
                direction={getResponsiveValue("column", "row")}
                spacing={2}
              >
                <Stack sx={{ flex: 1 }}>
                  <DynamicComponent {...col} />
                </Stack>
                <Stack sx={{ flex: 2 }}>
                  <DynamicComponent {...data.cols[index + 1]} />
                </Stack>
              </Stack>
            </Container>
          );
        }

        return <DynamicComponent key={index} {...col} />;
      })}
    </Stack>
  );
};

export default CustomPage;
