import {Grid2 as MuiGrid, Grid2Props} from "@mui/material";
import {Children, ReactNode} from "react";
import {getResponsiveValue} from "../utilities/hooks";

interface GridProps extends Pick<Grid2Props, 'rowSpacing' | 'columnSpacing' | 'spacing' | 'sx'> {
  children: ReactNode,
  desktop: number,
  mobile: number,
}

export const Grid = ({
  children,
  desktop,
  mobile,
  ...props
}: GridProps) => {
  return (
    <MuiGrid container {...props}>
      {Children.map(children, (child) => (
        <MuiGrid size={getResponsiveValue(mobile, desktop)}>
          {child}
        </MuiGrid>
      ))}
    </MuiGrid>
  );
}
